export const DIRECTORY_LINKS = [
  {
    key: "dealers",
    title: "UK Dealers",
    link: "/directory/category/dealers",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/business/dealers-background.webp`,
  },
  {
    key: "antique-fairs",
    title: "Antiques Fairs in the UK",
    link: "/directory/antique-fairs",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/business/fairs-background.webp`,
  },
  {
    key: "salvage-yards",
    title: "Salvage Yards",
    link: "/directory/category/salvage-yards",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/business/salvage-yards-background.webp`,
  },
  {
    key: "la-dealers",
    title: "LoveAntiques Dealers",
    link: "/directory/category/dealers/love-antiques-dealers",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/business/salvage-yards-background.webp`,
  },
  {
    key: "antique-centres",
    title: "Antique Centres",
    link: "/directory/category/antique-centres",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/business/antique-centres-background.webp`,
  },
  {
    key: "restoration-services",
    title: "Restoration Services",
    link: "/directory/category/restoration-services",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/business/restoration-background.webp`,
  },
  {
    key: "auction-houses",
    title: "Auction Houses",
    link: "/directory/category/auction-houses",
    image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/business/action-houses-background.webp`,
  },
];
